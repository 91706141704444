<template>
    <div>
        <v-checkbox
            v-model="settings.is_financial"
            :disabled="!canEnableFinancialDistribution"
            hide-details
        >
            <template #label>
                <div class="d-flex align-center font-weight-bold">
                    <v-icon
                        small
                        :color="
                            canEnableFinancialDistribution
                                ? 'purple lighten-1'
                                : 'grey'
                        "
                        left
                    >
                        fa-comment-dollar
                    </v-icon>
                    <b v-if="isAuthoredAndFinancial">
                        &nbsp;(Authored Amp)&nbsp;
                    </b>
                    {{
                        isAuthoredAndFinancial
                            ? 'Financial Distribution was requested'
                            : 'Enable Financial Distribution for this Amp ($1495)'
                    }}
                </div>
            </template>
        </v-checkbox>
        <ul
            class="pl-12 pt-4 body-2"
            :class="{ spaced: $vuetify.breakpoint.mdAndUp }"
        >
            <li>
                We guarantee publishing on Yahoo! Finance, and possibly NASDAQ.
            </li>
            <li>
                Your Amp will place higher in news readers like Google News and
                rank higher in Google Search, providing more exposure and
                traffic.
            </li>
            <li v-if="shouldAdvertise && !processingPayment">
                <strong>
                    Buy 1 Financial Distribution Credit Here for $1495.
                </strong>
            </li>
            <li v-if="isAuthoredAndFinancial">
                <b>
                    This Authored Amp is already written and marked to be
                    distributed to Financial sites.
                </b>
            </li>
            <li v-if="!isEditable && !processingPayment">
                <b>This Amp has already been sent to distribution.</b>
            </li>
            <li v-if="isLimitedDistribution">
                <b>
                    Selective Distribution Amps cannot have Financial
                    distribution.
                </b>
            </li>
        </ul>
        <v-expand-transition>
            <div v-if="settings.is_financial && !processingPayment">
                <v-divider class="my-6" />
                <amp-loa
                    :announcement="announcement"
                    :has-many-companies="hasManyCompanies"
                    :is-first-financial-amp="isFirstFinancialAmpAndNoPending"
                    @validate="setLoaValid"
                />
            </div>
        </v-expand-transition>

        <financial-info-modal v-if="canViewFinancialInfoOnPublish" />
    </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { Watch } from '@/utils/decorators';

import AmpLoa from './AmpLoa.vue';
import FinancialInfoModal from './FinancialInfoModal.vue';

import type { Announcement } from '@/types/Announcement';

const AmpFinancialProps = Vue.extend({
    name: 'AmpFinancial',
    props: {
        announcement: {
            type: Object as PropType<Announcement>,
            default() {
                return {
                    author_request_assignment: null,
                    press_release: null,
                    blog_post: null,
                    podcast: null,
                    infographic: null,
                    slideshow: null,
                    video_pr: null,
                    movie: null,
                    min_distribution_time: 0
                };
            }
        },
        processingPayment: {
            type: Boolean,
            default() {
                return false;
            }
        },
        hasFinancialCredits: {
            type: Boolean,
            default() {
                return false;
            }
        },
        hasManyCompanies: {
            type: Boolean,
            default() {
                return false;
            }
        },
        isFirstFinancialAmp: {
            type: Boolean,
            default() {
                return false;
            }
        },
        isFinancial: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        AmpLoa,
        FinancialInfoModal
    }
})
export default class AmpFinancial extends AmpFinancialProps {
    settings = {
        is_financial: this.isFinancial
    };

    isLoaValid = false;

    @Watch('settings', { deep: true })
    onSettingsChange() {
        if (this.settings.is_financial && this.canViewFinancialInfoOnPublish) {
            this.$store.dispatch('modal/open', 'financial-info-modal');
        }

        this.$emit('change', this.settings);
    }

    @Watch('isFinancial')
    onIsPremiumChange(isFinancial: boolean) {
        this.settings = { ...this.settings, is_financial: isFinancial };
    }

    get isAuthoredAndFinancial() {
        return (
            this.announcement?.author_request_assignment &&
            this.announcement.is_financial
        );
    }

    get canEnableFinancialDistribution() {
        return !(
            this.isAuthoredAndFinancial ||
            this.processingPayment ||
            this.isLimited
        );
    }

    get isLimited() {
        return !this.isEditable || this.isLimitedDistribution;
    }

    get isEditable() {
        return this.pressRelease?.is_editable;
    }

    get isLimitedDistribution() {
        return Boolean(this.pressRelease?.limited_distribution_only);
    }

    get pressRelease() {
        return this.announcement?.press_release || null;
    }

    get shouldAdvertise() {
        return (
            !this.hasFinancialCredits &&
            (!this.isAuthoredAndFinancial || !this.isEditable)
        );
    }

    get isFirstFinancialAmpAndNoPending() {
        return this.isFirstFinancialAmp && !this.isAuthoredAndFinancial;
    }

    get isMarkedFinancial() {
        return this.isAuthoredAndFinancial || this.settings.is_financial;
    }

    get canViewFinancialInfoOnPublish() {
        return (
            this.announcement.author_request_assignment &&
            !this.$route.query.financial
        );
    }

    setLoaValid(isValid = false) {
        this.isLoaValid = isValid;

        this.$emit('validate', this.isLoaValid);
    }
}
</script>

<style lang="scss" scoped>
.spaced {
    line-height: 1.8;
}
</style>
