import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isRendered)?_c(VDialog,{attrs:{"max-width":"840","persistent":""},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c(VCard,{staticClass:"modal-wrapper rounded-lg px-6 px-md-16"},[_c(VCardTitle,{staticClass:"pt-8"},[_c(VCol,{staticClass:"text-center"},[_c('h3',{staticClass:"secondary--text",class:{ 'mr-n12': _vm.$vuetify.breakpoint.mdAndUp }},[_vm._v(" Publish Full Amp? ")])]),_c(VCol,{attrs:{"cols":"auto"}},[_c(VBtn,{class:{ 'mr-n12': _vm.$vuetify.breakpoint.mobile },attrs:{"icon":""},on:{"click":_vm.close}},[_c(VIcon,[_vm._v("times")])],1)],1)],1),_c(VCardText,{staticClass:"text-center"},[_c('p',{staticClass:"pt-4 section-text"},[_vm._v(" You have both AmpiFire credits and PressCable distribution credits. ")]),_c('p',{staticClass:"pt-4 section-text"},[_vm._v(" Do you want to use an Amp credit to publish all six Amp modules (News Article, Blog Post, Podcast, Infographic, Slideshow, Video) or a PressCable credit to publish just the News Article? ")])]),_c(VCardActions,{staticClass:"justify-center"},[_c(VRow,{attrs:{"no-gutters":""}},[_c(VCol,{staticClass:"px-8 py-3"},[_c(VBtn,{staticClass:"text-h6",attrs:{"large":!_vm.$vuetify.breakpoint.mobile,"block":"","color":"primary"},on:{"click":function($event){return _vm.confirm(_vm.Action.UseAmpiFire)}}},[_vm._v(" Publish Full Amp ")])],1),_c(VCol,{staticClass:"px-8 pt-3 pb-12"},[_c(VBtn,{staticClass:"text-h6",attrs:{"large":!_vm.$vuetify.breakpoint.mobile,"block":"","color":"primary"},on:{"click":function($event){return _vm.confirm(_vm.Action.UsePressCable)}}},[_vm._v(" Publish Only News Article ")])],1)],1)],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }