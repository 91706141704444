<template>
    <div>
        <v-checkbox
            v-model="settings.is_msn"
            :disabled="!canEnableMsnDistribution"
            hide-details
        >
            <template #label>
                <div class="d-flex align-center font-weight-bold">
                    <v-icon
                        small
                        :color="canEnableMsnDistribution ? 'msn-blue' : 'grey'"
                        left
                    >
                        $vuetify.icons.msn
                    </v-icon>
                    Enable MSN Distribution for this Amp ($495)
                </div>
            </template>
        </v-checkbox>
        <ul
            class="pl-12 pt-4 body-2"
            :class="{ spaced: $vuetify.breakpoint.mdAndUp }"
        >
            <li>
                We guarantee publishing on the highly popular Microsoft site
                MSN.com. It has a Domain Authority (DA) of 83.
            </li>
            <li>
                Your news will be posted on MSN.com and be available to readers
                of MSN, in more news feeds, and give more overall exposure on a
                site that has over 700M visitors per month.
            </li>
            <li v-if="shouldAdvertise && !processingPayment">
                <strong>Buy 1 MSN Distribution Credit Here for $495.</strong>
            </li>
            <li v-if="!isEditable && !processingPayment">
                <b>This Amp has already been sent to distribution.</b>
            </li>
            <li v-if="isLimitedDistribution">
                <b>Selective Distribution Amps cannot have MSN distribution.</b>
            </li>
        </ul>

        <msn-info-modal v-if="canViewMSNInfoOnPublish" />
    </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { Watch } from '@/utils/decorators';

import type { Announcement } from '@/types/Announcement';
import MsnInfoModal from './MsnInfoModal.vue';

const AmpMsnProps = Vue.extend({
    name: 'AmpMsn',
    props: {
        announcement: {
            type: Object as PropType<Announcement>,
            default() {
                return {
                    author_request_assignment: null,
                    press_release: null,
                    blog_post: null,
                    podcast: null,
                    infographic: null,
                    slideshow: null,
                    video_pr: null,
                    movie: null,
                    min_distribution_time: 0
                };
            }
        },
        processingPayment: {
            type: Boolean,
            default() {
                return false;
            }
        },
        hasMsnCredits: {
            type: Boolean,
            default() {
                return false;
            }
        },
        isMsn: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        MsnInfoModal
    }
})
export default class AmpMsn extends AmpMsnProps {
    settings = {
        is_msn: this.isMsn
    };

    @Watch('settings', { deep: true })
    onSettingsChange() {
        if (this.settings.is_msn && this.canViewMSNInfoOnPublish) {
            this.$store.dispatch('modal/open', 'msn-info-modal');
        }

        this.$emit('change', this.settings);
    }

    @Watch('isMsn')
    onIsPremiumChange(isMsn: boolean) {
        this.settings = { ...this.settings, is_msn: isMsn };
    }

    get isAuthoredAndMsn() {
        return (
            this.announcement?.author_request_assignment &&
            this.announcement.is_msn
        );
    }

    get canEnableMsnDistribution() {
        return !(
            this.isAuthoredAndMsn ||
            this.processingPayment ||
            this.isLimited
        );
    }

    get isLimited() {
        return !this.isEditable || this.isLimitedDistribution;
    }

    get isEditable() {
        return this.pressRelease?.is_editable;
    }

    get isLimitedDistribution() {
        return Boolean(this.pressRelease?.limited_distribution_only);
    }

    get pressRelease() {
        return this.announcement?.press_release || null;
    }

    get shouldAdvertise() {
        return (
            !this.hasMsnCredits && (!this.isAuthoredAndMsn || !this.isEditable)
        );
    }

    get canViewMSNInfoOnPublish() {
        return !this.$route.query.msn;
    }
}
</script>

<style lang="scss" scoped>
.spaced {
    line-height: 1.8;
}
</style>
