<template>
    <v-skeleton-loader
        v-if="loading"
        loading
        type="heading"
        class="pa-1 pl-0"
    />
    <v-container v-else class="pa-0 title font-weight-regular">
        <v-row dense>
            <v-col md="5" sm="12" class="">
                {{ companyName }}
            </v-col>
            <v-col
                md="7"
                sm="12"
                class="font-italic"
                :class="{
                    'pt-0': $vuetify.breakpoint.smAndDown
                }"
            >
                {{ headline }}
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import type { Announcement } from '@/types/Announcement';

const AmpDetailsProps = Vue.extend({
    name: 'AmpDetails',
    props: {
        announcement: {
            type: Object as PropType<Announcement>,
            default() {
                return {
                    press_release: null,
                    company: null
                };
            }
        },
        loading: {
            type: Boolean,
            default() {
                return true;
            }
        }
    }
});

@Component
export default class AmpDetails extends AmpDetailsProps {
    get pressRelease() {
        return this.announcement?.press_release || null;
    }

    get companyName() {
        return this.announcement?.company?.name;
    }

    get headline() {
        return this.pressRelease?.headline || '';
    }
}
</script>
