<template>
    <div>
        <v-divider class="mb-6" />
        <label class="font-weight-bold">
            Modules
            <span class="font-weight-light ml-6">
                Specify modules that will be published
            </span>
        </label>
        <v-checkbox
            v-model="publish.press_release"
            hide-details
            class="always-on"
        >
            <template #label>
                <v-icon small left>file-alt</v-icon>
                News Article
                <status-chip
                    v-if="pressRelease"
                    class="ml-2"
                    :status="pressRelease.status_string"
                />
                <status-chip
                    v-else-if="!isLimitedDistribution"
                    class="ml-2"
                    status="Module not created"
                />
            </template>
        </v-checkbox>
        <div class="d-flex align-end">
            <v-checkbox
                v-model="publish.blog_post"
                label="Blog Post"
                hide-details
                :disabled="!publishable.blog_post"
            >
                <template #label>
                    <v-icon small left>pen-square</v-icon>
                    Blog Post
                    <status-chip
                        v-if="blogPost"
                        class="ml-2"
                        :status="blogPost.status_string"
                    />
                    <status-chip
                        v-else-if="!isLimitedDistribution"
                        class="ml-2"
                        status="Module not created"
                    />
                </template>
            </v-checkbox>

            <router-link
                v-if="!publishable.blog_post && !isLimitedDistribution"
                class="ml-2"
                :to="getModuleLink('blog-post')"
                @click.native="onModuleLinkClick"
            >
                Review
            </router-link>
        </div>
        <div class="d-flex align-end">
            <v-checkbox
                v-model="publish.podcast"
                label="Podcast"
                hide-details
                :disabled="!publishable.podcast"
            >
                <template #label>
                    <v-icon small left>headphones</v-icon>
                    Podcast
                    <status-chip
                        v-if="podcast"
                        class="ml-2"
                        :status="podcast.status_string"
                    />
                    <status-chip
                        v-else-if="!isLimitedDistribution"
                        class="ml-2"
                        status="Module not created"
                    />
                </template>
            </v-checkbox>

            <router-link
                v-if="!publishable.podcast && !isLimitedDistribution"
                class="ml-2"
                :to="getModuleLink('podcast')"
                @click.native="onModuleLinkClick"
            >
                Review
            </router-link>
        </div>
        <div class="d-flex align-end">
            <v-checkbox
                v-model="publish.infographic"
                label="Infographic"
                hide-details
                :disabled="!publishable.infographic"
            >
                <template #label>
                    <v-icon small left>file-image</v-icon>
                    Infographic
                    <status-chip
                        v-if="infographic"
                        class="ml-2"
                        :status="infographic.status_string"
                    />
                    <status-chip
                        v-else-if="!isLimitedDistribution"
                        class="ml-2"
                        status="Module not created"
                    />
                </template>
            </v-checkbox>

            <router-link
                v-if="!publishable.infographic && !isLimitedDistribution"
                class="ml-2"
                :to="getModuleLink('infographic')"
                @click.native="onModuleLinkClick"
            >
                Review
            </router-link>
        </div>
        <div class="d-flex align-end">
            <v-checkbox
                v-model="publish.slideshow"
                label="Slideshow"
                hide-details
                :disabled="!publishable.slideshow"
            >
                <template #label>
                    <v-icon small left>tv</v-icon>
                    Slideshow
                    <status-chip
                        v-if="slideshow"
                        class="ml-2"
                        :status="slideshow.status_string"
                    />
                    <status-chip
                        v-else-if="!isLimitedDistribution"
                        class="ml-2"
                        status="Module not created"
                    />
                </template>
            </v-checkbox>

            <router-link
                v-if="!publishable.slideshow && !isLimitedDistribution"
                class="ml-2"
                :to="getModuleLink('slideshow')"
                @click.native="onModuleLinkClick"
            >
                Review
            </router-link>
        </div>
        <div class="d-flex align-end">
            <v-checkbox
                v-model="publish.video"
                label="Video"
                hide-details
                :disabled="!publishable.video"
            >
                <template #label>
                    <v-icon small left>play-circle</v-icon>
                    Video
                    <status-chip
                        v-if="videoPr"
                        class="ml-2"
                        :status="videoPr.status_string"
                    />
                    <status-chip
                        v-else-if="!isLimitedDistribution"
                        class="ml-2"
                        status="Module not created"
                    />
                </template>
            </v-checkbox>

            <router-link
                v-if="!publishable.video && !isLimitedDistribution"
                class="ml-2"
                :to="getModuleLink('video')"
                @click.native="onModuleLinkClick"
            >
                Review
            </router-link>
        </div>
    </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { Watch } from '@/utils/decorators';

import { StatusChip } from '@/components/StatusChip';

import type { Announcement } from '@/types/Announcement';
import type { PublishableModules } from '..';

import { AAlert } from '@/components/AAlert';

const AmpModulesProps = Vue.extend({
    name: 'AmpModules',
    props: {
        announcement: {
            type: Object as PropType<Announcement>,
            default() {
                return {
                    press_release: null,
                    blog_post: null,
                    podcast: null,
                    infographic: null,
                    slideshow: null,
                    video_pr: null
                };
            }
        },
        publishable: {
            type: Object as PropType<PublishableModules>,
            default() {
                return {
                    press_release: true,
                    blog_post: true,
                    infographic: true,
                    podcast: true,
                    slideshow: true,
                    video: true
                };
            }
        }
    }
});

@Component<AmpModules>({
    components: {
        StatusChip,
        AAlert
    }
})
export default class AmpModules extends AmpModulesProps {
    publish = {
        press_release: true,
        blog_post: true,
        podcast: true,
        infographic: true,
        slideshow: true,
        video: true
    };

    @Watch('publish', { deep: true })
    onPublishChange() {
        this.$emit('change', this.publish);
    }

    @Watch('publishable', { deep: true })
    onPublishableChange() {
        this.setPublishOptions();
    }

    get pressRelease() {
        return this.announcement?.press_release || null;
    }

    get blogPost() {
        return this.announcement?.blog_post || null;
    }

    get podcast() {
        return this.announcement?.podcast || null;
    }

    get infographic() {
        return this.announcement?.infographic || null;
    }

    get slideshow() {
        return this.announcement?.slideshow || null;
    }

    get videoPr() {
        return this.announcement?.video_pr || null;
    }

    get isLimitedDistribution() {
        return Boolean(this.pressRelease?.limited_distribution_only);
    }

    mounted() {
        this.setPublishOptions();
    }

    setPublishOptions() {
        if (this.announcement) {
            this.publish.press_release = this.publishable.press_release;

            this.publish.blog_post = this.announcement.blog_post
                ? this.publishable.blog_post
                : false;

            this.publish.podcast = this.announcement.podcast
                ? this.publishable.podcast
                : false;

            this.publish.infographic = this.announcement.infographic
                ? this.publishable.infographic
                : false;

            this.publish.slideshow = this.announcement.slideshow
                ? this.publishable.slideshow
                : false;

            this.publish.video = this.announcement.video_pr
                ? this.publishable.video
                : false;
        }
    }

    getModuleLink(module: string) {
        return `/announcements/edit/${this.$route.params.announcementId}/${module}`;
    }

    onModuleLinkClick(e: MouseEvent) {
        e.stopImmediatePropagation();

        this.$store.dispatch(
            'cachekey/reset',
            this.$route.params.announcementId
        );
    }
}
</script>

<style lang="scss" scoped>
.always-on::v-deep {
    pointer-events: none;

    .v-input--selection-controls__input {
        opacity: 0.4;
    }
}
</style>
