<template>
    <div>
        <div class="d-flex align-center font-weight-bold">
            Letter of Authorization Required
        </div>
        <p class="body-2 pt-4">
            Yahoo! requires that the Press Release Distribution Authorization
            Form be included with each Premium Amp campaign to permit
            publication to high-value Yahoo! sites. Once you have filled this
            form out on {{ businessName }} once, we will fill it in for you in
            the future.
        </p>
        <div v-if="isFirstFinancialAmp">
            <p class="body-2">
                Please email
                <a class="d-inline-block" :href="loaMailToLink">
                    {{ businessEmail }}
                </a>
                and cc
                <a class="d-inline-block" :href="loaMailToLink">
                    {{ ccEmail }}
                </a>
                with the subject line
                <i>{{ loaMailSubject }}</i>
                and the following copy:
            </p>
            <p class="body-2 font-italic">
                {{ loaMailBody }}
            </p>
            <p class="body-2">
                This email must come from an email address with the same root
                domain as the company and link being promoted by the news
                article. Any email coming from a @gmail.com or similar free
                email account will be rejected.
            </p>
        </div>
        <template v-if="needsAgencyLoA">
            <p class="body-2">
                If you are publishing this Amp on behalf of a company other than
                your own, please also upload an Agency Letter of Authorization.
            </p>
            <p class="body-2">
                A step-by-step guide with more information on both types of
                Letter of Authorization
                <a
                    href="https://docs.google.com/document/d/1MSVs1vRVFNQInqYDdB_6D0Bqz1V53lFnfnG1mcu5F5s/edit"
                    class="d-inline-block"
                    target="_blank"
                >
                    is available here
                </a>
                .
            </p>
        </template>
        <template v-else>
            <p class="body-2">
                A step-by-step guide with more information on Letter of
                Authorization
                <a
                    href="https://docs.google.com/document/d/1MSVs1vRVFNQInqYDdB_6D0Bqz1V53lFnfnG1mcu5F5s/edit"
                    class="d-inline-block"
                    target="_blank"
                >
                    is available here
                </a>
                .
            </p>
        </template>
        <v-container class="px-0">
            <v-row>
                <v-col cols="12" md="6">
                    <v-btn
                        color="white"
                        class="primary--text"
                        :block="$vuetify.breakpoint.smAndDown"
                        :disabled="isValidating"
                        :to="loaFormLink"
                    >
                        <v-icon
                            v-if="isValid && !isValidating"
                            small
                            left
                            color="green"
                        >
                            square-check
                        </v-icon>
                        <v-icon
                            v-if="!isValid && !isValidating"
                            small
                            left
                            color="orange"
                        >
                            circle-exclamation
                        </v-icon>
                        <v-progress-circular
                            v-if="isValidating"
                            indeterminate
                            color="primary"
                            class="mr-2 ml-n1"
                            size="16"
                            width="2"
                        />
                        Letter of Authorization
                        <v-icon small right>arrow-right</v-icon>
                    </v-btn>
                </v-col>
                <v-col v-if="needsAgencyLoA" cols="12" md="6">
                    <v-btn
                        color="white"
                        class="primary--text"
                        :block="$vuetify.breakpoint.smAndDown"
                        :to="agencyLoaLink"
                    >
                        Agency Letter of Authorization
                        <v-icon small right>arrow-right</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';
import { validate } from 'vee-validate';
import { mapGetters } from 'vuex';

import { uuid } from '@/utils/helpers';

import type { Announcement } from '@/types/Announcement';
import type { Company } from '@/types/Company';

const AmpLoaProps = Vue.extend({
    name: 'AmpLoa',
    props: {
        announcement: {
            type: Object as PropType<Announcement>,
            default() {
                return {
                    press_release: null,
                    company_id: 0,
                    company: null
                };
            }
        },
        isFirstFinancialAmp: {
            type: Boolean,
            default() {
                return false;
            }
        },
        hasManyCompanies: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

type CompanyPropertyValidator = (
    company: Partial<Company>
) => boolean | Promise<boolean>;

@Component<AmpLoa>({
    computed: {
        ...mapGetters('user', ['isReseller'])
    }
})
export default class AmpLoa extends AmpLoaProps {
    isReseller!: boolean;

    businessName = 'AmpiFire';
    businessEmail = 'support@ampifire.com';
    ccEmail = 'authorization@ampifire.com';

    isValidating = false;
    isValid = false;
    // LoA validators, be careful
    validators: CompanyPropertyValidator[] = [
        company => !!company.name,
        company => !!company.full_address,
        company => !!company.website,
        company => !!company.phone,
        company => company.entity_type !== null,
        company =>
            company.entity_type === 0 ? !!company.entity_type_other : true,
        company => !!company.registration_number,
        company => !!company.media_contact_name,
        company => !!company.media_contact_phone,
        company => !!company.media_contact_designation,
        company => !!company.media_contact_email,
        company => !!company.representative_contact_name,
        company => !!company.representative_contact_phone,
        company => !!company.representative_contact_designation,
        company =>
            validate(
                company.representative_contact_email,
                'required|email|no_free_domains'
            ).then(({ valid }) => valid),
        company => !!company.company_signatory,
        company => !!company.authorized_contact_name,
        company => !!company.authorized_contact_phone,
        company => !!company.authorized_contact_designation
    ];

    get pressRelease() {
        return this.announcement?.press_release || null;
    }

    get companyName() {
        return this.announcement?.company?.name;
    }

    get needsLoA() {
        return !this.announcement?.company?.interwire_letter_of_authorization;
    }

    get needsAgencyLoA() {
        return this.isReseller || this.hasManyCompanies;
    }

    get headline() {
        return this.pressRelease?.headline || '';
    }

    get loaFormLink() {
        return `/announcements/publish/${this.$route.params.announcementId}/loa`;
    }

    get agencyLoaLink() {
        return [this.loaFormLink, 'agency'].join('/');
    }

    get loaMailToLink() {
        return [
            `mailto:${this.businessEmail}`,
            [
                `cc=${this.ccEmail}`,
                `subject=${this.loaMailSubject}`,
                `body=${this.loaMailBody}`
            ].join('&')
        ].join('?');
    }

    get loaMailSubject() {
        return `${this.companyName} - Authorize ${this.businessName}`;
    }

    get loaMailBody() {
        return `I authorize ${this.businessName} to publish this and future news articles on behalf of ${this.companyName}.`;
    }

    activated() {
        // keep-alive hook
        this.validate();
    }

    mounted() {
        this.validate();
    }

    validate() {
        if (this.pressRelease) {
            this.isValidating = true;

            this.getCompany(this.announcement?.company_id)
                .then(({ company }) => {
                    if (company) {
                        return this.runValidators(company);
                    }
                })
                .finally(() => {
                    this.isValidating = false;

                    this.onValidate();
                });
        }
    }

    getCompany(id: string | number) {
        return this.$http
            .get(`/companies/view/${id}`, {
                id: this.getCacheId() // don't cache
            })
            .then(({ data }) => {
                if (data) {
                    return data.data || {};
                }

                return {};
            })
            .catch(error => {
                if (!error.isIntercepted) {
                    this.$store.dispatch('notification/error', error);
                }

                return {};
            });
    }

    getCacheId() {
        return uuid();
    }

    async runValidators(data: Partial<Company>) {
        const validation = await Promise.all(
            this.validators.map(validator => validator(data))
        );

        this.isValid = validation.every(result => Boolean(result));
    }

    onValidate() {
        this.$emit('validate', this.isValid);
    }
}
</script>
