import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.loading)?_c(VSkeletonLoader,{staticClass:"pa-1 pl-0",attrs:{"loading":"","type":"heading"}}):_c(VContainer,{staticClass:"pa-0 title font-weight-regular"},[_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"md":"5","sm":"12"}},[_vm._v(" "+_vm._s(_vm.companyName)+" ")]),_c(VCol,{staticClass:"font-italic",class:{
                'pt-0': _vm.$vuetify.breakpoint.smAndDown
            },attrs:{"md":"7","sm":"12"}},[_vm._v(" "+_vm._s(_vm.headline)+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }