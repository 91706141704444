<template>
    <v-container class="amp-links">
        <v-row>
            <v-col class="pa-0 d-flex justify-end">
                <template v-for="(link, i) in links">
                    <v-tooltip v-if="warn && link.warn" :key="i" bottom>
                        <template #activator="{ on, attrs }">
                            <v-btn
                                :key="i"
                                :class="link.type"
                                :disabled="isNavigatingOtherLink(i)"
                                :loading="isNavigating(i)"
                                class="px-10 amp-link-button"
                                v-bind="attrs"
                                v-on="on"
                                @click.native="warnLink(link)"
                            >
                                {{ link.label }}
                            </v-btn>
                        </template>
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <span class="d-block text-center">
                            Altering your authored Amp's content will mean
                            AmpiFire must review the edits
                            <br />
                            After you Publish this Amp will be re-edited and
                            returned to you
                        </span>
                    </v-tooltip>
                    <v-btn
                        v-else
                        :key="i"
                        :class="link.type"
                        :to="link.to"
                        :disabled="isNavigatingOtherLink(i)"
                        :loading="isNavigating(i)"
                        class="px-10 amp-link-button"
                        @click.native="setNavigating(i)"
                    >
                        {{ link.label }}
                    </v-btn>
                </template>
            </v-col>
        </v-row>
        <warn-module-editing v-if="warn" :link="link" />
    </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { Watch } from '@/utils/decorators';

import WarnModuleEditing from './WarnModuleEditing.vue';

import type { ModuleLink } from '@/types/ModuleLink';

const AmpModuleLinkProps = Vue.extend({
    name: 'AmpModuleLink',
    props: {
        links: {
            type: Array as PropType<ModuleLink[]>,
            default() {
                return [];
            }
        },
        warn: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        WarnModuleEditing
    }
})
export default class AmpModuleLink extends AmpModuleLinkProps {
    navigatingLinkIndex = -1;

    link: ModuleLink | null = null;

    @Watch('links')
    onLinksChange() {
        this.navigatingLinkIndex = -1;
    }

    isNavigatingOtherLink(i: number) {
        return (
            this.navigatingLinkIndex !== -1 && this.navigatingLinkIndex !== i
        );
    }

    isNavigating(i: number) {
        return this.navigatingLinkIndex === i;
    }

    setNavigating(i: number) {
        this.navigatingLinkIndex = i;
    }

    warnLink(link: ModuleLink) {
        this.link = link;

        this.$store.dispatch('modal/open', 'warn-module-editing');
    }
}
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';
.amp-link-button.v-size--default {
    min-width: 140px;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
    .amp-links {
        position: fixed;
        z-index: 10;
        left: 0;
        right: 0;
        bottom: 0;
        padding-top: 24px;
    }

    .amp-link-button {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 0;
    }
}
</style>
