<template>
    <a-form ref="form">
        <v-checkbox
            v-model="settings.is_guest_post_premium"
            :disabled="!canEnableGuestPostPremium"
            hide-details
        >
            <template #label>
                <div class="d-flex align-center font-weight-bold">
                    <v-icon small color="red" left>fire-flame-curved</v-icon>
                    Enable BLOW (Blog Link Outreach Wire) Distribution for this
                    Amp ({{ blowCreditsOrPrice }})
                </div>
            </template>
        </v-checkbox>
        <ul
            class="pl-12 pt-4 body-2"
            :class="{ spaced: $vuetify.breakpoint.mdAndUp }"
        >
            <li>
                Amplify this campaign further with our DA40+ Blog Link Outreach
                Wire campaign.
            </li>
            <li>
                We guarantee publishing 4 unique articles linking back to the
                page you are advertising in this campaign across 4 blogs with a
                Domain Authority rating of 40 or higher (1 unique article for
                each DA40 site).
            </li>
            <li v-if="hasPublishedGuestPost">
                <b>This Amp has already been sent to distribution.</b>
            </li>
        </ul>
        <v-expand-transition v-if="canEnableGuestPostPremium" @enter="onToggle">
            <div v-if="settings.is_guest_post_premium" class="pl-12 pt-4">
                <a-text-input
                    v-model="settings.guest_post_url"
                    label="Promoted URL"
                    rules="max:512|url:secure"
                    placeholder="https://..."
                    persistent-hint
                    auto-grow
                    :disabled="isPublishing"
                />
            </div>
        </v-expand-transition>
    </a-form>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { AForm } from '@/components/AForm';
import { ATextInput } from '@/components/AForm/Inputs/ATextInput';

const AmpGuestPostPremiumProps = Vue.extend({
    name: 'AmpGuestPostPremium',
    props: {
        processingPayment: {
            type: Boolean,
            default() {
                return false;
            }
        },
        isPublishing: {
            type: Boolean,
            default() {
                return false;
            }
        },
        guestPostUrl: {
            type: String,
            default() {
                return '';
            }
        },
        isGuestPostPremium: {
            type: Boolean,
            default() {
                return false;
            }
        },
        hasPublishedGuestPost: {
            type: Boolean,
            default() {
                return false;
            }
        },
        guestPostCreditsCount: {
            type: Number,
            default() {
                return 0;
            }
        }
    }
});

@Component<AmpGuestPostPremium>({
    components: {
        AForm,
        ATextInput
    },
    watch: {
        settings: {
            handler() {
                this.$emit('change', this.settings);

                this.validate();
            },
            deep: true
        },
        isGuestPostPremium(isGuestPostPremium: boolean) {
            this.settings = Object.assign({}, this.settings, {
                is_guest_post_premium: isGuestPostPremium
            });
        },
        guestPostUrl(guestPostUrl: string) {
            this.settings = Object.assign({}, this.settings, {
                guest_post_url: guestPostUrl
            });
        }
    }
})
export default class AmpGuestPostPremium extends AmpGuestPostPremiumProps {
    $refs!: {
        form: InstanceType<typeof AForm>;
    };
    settings = {
        is_guest_post_premium:
            this.isGuestPostPremium || this.hasPublishedGuestPost,
        guest_post_url: this.guestPostUrl
    };

    get canEnableGuestPostPremium() {
        return !(this.processingPayment || this.hasPublishedGuestPost);
    }

    get blowCreditsOrPrice() {
        if (this.guestPostCreditsCount > 0) {
            return `${Math.floor(
                this.guestPostCreditsCount / 4
            )} BLOW Packs Remaining`;
        }
        return `$997`;
    }

    async validate() {
        const isValid = await this.$refs.form.validate();

        this.$emit('validate', isValid);
    }

    onToggle() {
        this.validate();
    }
}
</script>

<style lang="scss" scoped>
.spaced {
    line-height: 1.8;
}
</style>
