<template>
    <modal
        id="financial-info-modal"
        title="Financial Distribution"
        :closable="false"
    >
        <a-alert type="info">
            This will require extensive edits due to Yahoo's content policies so
            the Amp will be returned to the authoring team and will require the
            customer to publish again after changes are made.
        </a-alert>

        <template #actions>
            <v-spacer />
            <v-btn color="primary" @click.stop="close">Continue</v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { Modal } from '@/components/Modal';
import { AAlert } from '@/components/AAlert';

@Component({
    components: {
        Modal,
        AAlert
    }
})
export default class FinancialInfoModal extends Vue {
    close() {
        this.$store.dispatch('modal/close', 'financial-info-modal');
    }
}
</script>
