import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"amp-links"},[_c(VRow,[_c(VCol,{staticClass:"pa-0 d-flex justify-end"},[_vm._l((_vm.links),function(link,i){return [(_vm.warn && link.warn)?_c(VTooltip,{key:i,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,_vm._g(_vm._b({key:i,staticClass:"px-10 amp-link-button",class:link.type,attrs:{"disabled":_vm.isNavigatingOtherLink(i),"loading":_vm.isNavigating(i)},nativeOn:{"click":function($event){return _vm.warnLink(link)}}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(link.label)+" ")])]}}],null,true)},[_c('span',{staticClass:"d-block text-center"},[_vm._v(" Altering your authored Amp's content will mean AmpiFire must review the edits "),_c('br'),_vm._v(" After you Publish this Amp will be re-edited and returned to you ")])]):_c(VBtn,{key:i,staticClass:"px-10 amp-link-button",class:link.type,attrs:{"to":link.to,"disabled":_vm.isNavigatingOtherLink(i),"loading":_vm.isNavigating(i)},nativeOn:{"click":function($event){return _vm.setNavigating(i)}}},[_vm._v(" "+_vm._s(link.label)+" ")])]})],2)],1),(_vm.warn)?_c('warn-module-editing',{attrs:{"link":_vm.link}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }