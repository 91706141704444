<template>
    <v-container fluid class="pa-0">
        <v-container fluid class="amp-module-header">
            <v-container v-if="isLoading" class="px-3 pt-6 pb-6">
                <v-skeleton-loader
                    loading
                    type="heading"
                    width="25%"
                    class="mb-4"
                />
                <v-skeleton-loader
                    loading
                    type="text"
                    width="20%"
                    class="mb-2"
                />
                <v-skeleton-loader loading type="heading" />
            </v-container>
            <v-container v-else>
                <v-row>
                    <v-col cols="12" md="8" class="d-flex flex-column pt-6">
                        <div class="pb-2">
                            <status-chip
                                :status="statusString"
                                :tooltip="statusTooltip"
                                with-icon
                            >
                                <template v-if="isDraft" #icon>
                                    <v-icon class="pl-2 pb-1" x-small>
                                        info-circle
                                    </v-icon>
                                </template>
                            </status-chip>
                            <status-chip
                                v-if="assignment?.author_request.frequency"
                                status="Recurring"
                                class="ml-2"
                            />
                            <status-chip
                                v-if="isLimitedDistribution"
                                status="Selective Distribution"
                                class="ml-1"
                            />
                            <status-chip
                                v-if="isPremium"
                                status="Premium"
                                class="ml-2"
                            />
                            <status-chip
                                v-if="isFinancial"
                                status="Financial"
                                class="ml-2"
                            />
                            <status-chip
                                v-if="isMsn"
                                status="MSN"
                                class="ml-2"
                            />
                            <status-chip
                                v-if="isGuestPostPremium"
                                status="B.L.O.W."
                                class="ml-2"
                            />
                        </div>
                        <span class="font-weight-medium">
                            {{ categoryName }}
                        </span>
                        <div
                            class="text-h6 text-md-h5 font-weight-bold d-flex align-center company-name"
                        >
                            <text-truncated
                                v-if="hasCompany"
                                :text="companyName"
                            />
                            <span v-else>No Company Selected</span>
                            <v-btn
                                v-show="!isExpanded && !isPublishing"
                                class="ml-2"
                                text
                                fab
                                small
                                :loading="isLoading"
                                @click="toggleEditMode"
                            >
                                <v-icon v-if="amp.editable">edit</v-icon>
                                <v-icon v-else>eye</v-icon>
                            </v-btn>
                        </div>
                        <div
                            v-if="shouldDisplayPublishedDate"
                            class="text-caption mb-n4"
                        >
                            Publish Date: {{ publishedAt }}
                        </div>
                    </v-col>
                    <v-col
                        cols="12"
                        md="4"
                        class="align-center d-flex justify-end"
                    >
                        <assignment-actions
                            v-if="canAct"
                            class="author-request-actions"
                            :class="{
                                mobile: $vuetify.breakpoint.smAndDown
                            }"
                            can-review
                            :can-change-frequency="canChangeFrequency"
                            @show-comments="showComments"
                            @update="load"
                            @redirect="redirect"
                        />
                        <v-tooltip bottom>
                            <template #activator="{ on, attrs }">
                                <v-btn
                                    v-if="isPublishable"
                                    :block="
                                        !canReport &&
                                        $vuetify.breakpoint.smAndDown
                                    "
                                    :loading="isPublishing"
                                    :disabled="isExpanded || isDirty"
                                    color="white"
                                    class="px-10"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="publish"
                                >
                                    Go to Publish
                                </v-btn>
                            </template>
                            <span>
                                Review distribution options and publish Amp
                            </span>
                        </v-tooltip>
                        <v-btn
                            v-if="canReport"
                            class="px-9 ml-2"
                            :to="reportUrl"
                            color="primary"
                        >
                            <v-icon small color="white" left>file</v-icon>
                            Report
                        </v-btn>
                    </v-col>
                </v-row>
            </v-container>
        </v-container>
        <v-expand-transition>
            <amp-module-editor
                v-if="isExpanded"
                :amp-id="ampId"
                :cache="cacheId"
                :can-add-company="!isPublishing"
                @dirty="setDirty"
                @update="update"
                @cancel="collapse"
            />
        </v-expand-transition>
        <v-overlay absolute :value="isExpanded" z-index="2" @click="collapse" />
        <slot
            :create="createModule"
            :reload="reload"
            :set-pre-publish-hook="setPrePublishHook"
        ></slot>
        <assignment-comments
            v-if="canAct"
            :assignment-id="showCommentsForAssignmentId"
            @close="hideComments"
            @upate="update"
        />
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { mapGetters } from 'vuex';

import { Endpoint } from '@/mixins';
import { formatDateTime } from '@/utils/helpers';
import { ProvideReactive } from '@/utils/decorators';

import { StatusChip } from '@/components/StatusChip';
import { TextTruncated } from '@/components/TextTruncated';
import { AmpModuleEditor } from '@/components/AmpModule';

import { AssignmentActions } from '@/components/Authoring/AssignmentActions';
import { AssignmentComments } from '@/components/Authoring/AssignmentComments';

import { PrEventStatus } from '@/types/PrEvent';

import type { AmpEdit, AmpModules, Announcement } from '@/types/Announcement';
import type { Assignment } from '@/types/Assignment';

type PrePublishHook = null | (() => Promise<boolean>);

const AmpModuleHeaderProps = Vue.extend({
    name: 'AmpModuleHeader',
    props: {
        ampId: {
            type: [String, Number],
            default() {
                return 0;
            }
        }
    }
});

@Component<AmpModuleHeader>({
    components: {
        AssignmentActions,
        AssignmentComments,
        StatusChip,
        TextTruncated,
        AmpModuleEditor
    },
    computed: {
        ...mapGetters('user', ['isEditor', 'isAdmin', 'isAuthor'])
    }
})
export default class AmpModuleHeader extends mixins(
    AmpModuleHeaderProps,
    Endpoint
) {
    isAuthor!: boolean;
    isEditor!: boolean;
    isAdmin!: boolean;

    isExpanded = false;
    isDirty = false;
    isPublishing = false;

    showCommentsForAssignmentId = 0;

    amp: Partial<AmpEdit> = {};

    endpoint = '/announcements/edit';

    prePublishHook: PrePublishHook = null;

    @ProvideReactive() announcement: Partial<Announcement> = {};
    @ProvideReactive() modules: AmpModules | null = null;
    @ProvideReactive() assignment: Assignment | null = null;
    @ProvideReactive() viewingPage = true;

    get categoryName() {
        return this.announcement?.category?.name || 'Please select a category';
    }

    get sourceUrl() {
        return [this.endpoint, this.ampId].join('/');
    }

    get statusTooltip() {
        return this.isDraft
            ? 'This Amp has modules that have not been published'
            : '';
    }

    get isDraft() {
        return this.isPending && this.announcement?.has_draft_status;
    }

    get canReport() {
        return this.isPending || this.isCompleted;
    }

    get reportUrl() {
        return `/announcements/report/${this.ampId}`;
    }

    get isPublishable() {
        if (this.isAuthor) {
            return this.amp.publishable && (this.isEditor || this.isAdmin);
        }

        return this.amp.publishable;
    }

    get canAct() {
        return Boolean(!this.isLoading && this.assignment);
    }

    get canChangeFrequency() {
        return this.isEditor;
    }

    get isPending() {
        return (
            this.announcement.has_pending_status ||
            this.announcement.has_published_status
        );
    }

    get isCompleted() {
        return this.announcement.is_fully_published;
    }

    get isLimitedDistribution() {
        return this.announcement.press_release?.limited_distribution_only;
    }

    get statusString() {
        return this.announcement.press_release?.status_string;
    }

    get hasCompany() {
        return Boolean(this.company && this.company?.id);
    }

    get company() {
        return this.announcement?.company;
    }

    get companyName() {
        return this.company?.name;
    }

    get isPremium() {
        return this.announcement.is_premium;
    }

    get isFinancial() {
        return this.announcement.is_financial;
    }

    get isMsn() {
        return this.announcement.is_msn;
    }

    get isGuestPostPremium() {
        return this.announcement.is_guest_post_premium;
    }

    get shouldDisplayPublishedDate() {
        return (
            typeof this.announcement?.status !== 'undefined' &&
            [PrEventStatus.Pending, PrEventStatus.Live].includes(
                this.announcement?.status
            )
        );
    }

    get publishedAt() {
        return this.announcement?.min_distribution_time
            ? formatDateTime(this.announcement?.min_distribution_time)
            : '';
    }

    onMounted() {
        if (this.ampId) {
            this.load();
        }
    }

    onData(data: {
        announcement: Announcement;
        amp: AmpEdit;
        modules: AmpModules;
    }) {
        this.amp = { ...data.amp };

        this.modules = data.modules;

        this.setAnnouncement(data.announcement);

        this.onReady();
    }

    toggleEditMode() {
        this.isExpanded = !this.isExpanded;
    }

    collapse() {
        if (!this.isDirty) {
            this.toggleEditMode();
        }
    }

    setDirty(isDirty: boolean) {
        this.isDirty = isDirty;
    }

    setAnnouncement(announcement: Announcement) {
        this.announcement = { ...announcement };

        this.assignment = this.announcement.author_request_assignment || null;
    }

    async publish() {
        this.isPublishing = true;

        const canPublish =
            typeof this.prePublishHook === 'function'
                ? await this.prePublishHook()
                : true;

        if (canPublish) {
            // redirects to publishing page
            this.$emit('publish');
        }

        this.isPublishing = false;
    }

    async createModule(endpoint: string) {
        await this.$http.get(
            [endpoint, `?announcement_id=${this.ampId}&create=1`].join('/')
        );

        return this.load();
    }

    async reload(route?: string) {
        await this.load();

        if (route) {
            this.$router.push(route);
        }
    }

    setPrePublishHook(hook: PrePublishHook) {
        this.prePublishHook = hook;
    }

    showComments(id: number) {
        this.showCommentsForAssignmentId = id;
    }

    hideComments() {
        this.showCommentsForAssignmentId = 0;
    }

    redirect(location: string) {
        window.location.href = location;
    }

    onReady() {
        this.$emit('ready', {
            announcement: this.announcement,
            modules: this.modules
        });
    }
}
</script>

<style lang="scss" scoped>
.amp-module-header::v-deep {
    background-color: $blueish;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.1);
    z-index: 4;
    position: relative;

    .company-name {
        min-height: 40px;
    }

    .author-request-actions {
        position: relative;
        top: calc(-50% + 10px);

        &.mobile {
            position: absolute;
            top: 6px;
            right: 0;
        }
    }
}

@media only screen and (min-width: #{map-get($grid-breakpoints, 'md') + 175}) and (max-width: #{map-get($grid-breakpoints, 'lg') - 0.02}), only screen and (min-width: #{map-get($grid-breakpoints, 'lg') + 175}) and (max-width: #{map-get($grid-breakpoints, 'xl') - 0.02}) {
    .amp-module-header::v-deep .author-request-actions {
        position: absolute;
        z-index: 3;
        top: 1.75em;
        right: 1em;
    }
}
</style>
