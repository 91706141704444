<template>
    <modal :id="id" title="Authored Amp">
        <template #default>
            <p>
                Your authored Amp has been created with the requirements of our
                distribution partners in mind, in order to get you the best
                search appearances and the best brand mentions. We've carefully
                used keywords in headlines and throughout the content of your
                Amp.
            </p>
            <p>
                You can change this content, but upon Publish we will send the
                Amp back to our editors to ensure the compliance standards of
                our distribution partners are still being met. Please be aware
                this may cause delays for distribution.
            </p>
        </template>
        <template #actions>
            <v-btn text :block="$vuetify.breakpoint.smAndDown" @click="close">
                Cancel
            </v-btn>
            <v-spacer />
            <v-btn
                color="primary"
                :block="$vuetify.breakpoint.smAndDown"
                @click="edit"
            >
                Edit
            </v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { Modal } from '@/components/Modal';
import { ModuleLink } from '@/types/ModuleLink';

const WarnModuleEditingProps = Vue.extend({
    name: 'WarnModuleEditing',
    props: {
        link: {
            type: Object as PropType<ModuleLink | null>,
            default(): ModuleLink | null {
                return null;
            }
        }
    }
});

@Component({
    components: {
        Modal
    }
})
export default class WarnModuleEditing extends WarnModuleEditingProps {
    id = 'warn-module-editing';

    edit() {
        if (this.link?.to) {
            this.$router.push(this.link.to);
        }

        this.close();
    }

    close() {
        this.$store.dispatch('modal/close', this.id);
    }
}
</script>
