<template>
    <div>
        <v-checkbox
            v-model="settings.is_premium"
            :disabled="!canEnablePremiumDistribution"
            hide-details
        >
            <template #label>
                <div class="d-flex align-center font-weight-bold">
                    <v-icon
                        small
                        :color="
                            canEnablePremiumDistribution ? 'turquoise' : 'grey'
                        "
                        left
                    >
                        gem
                    </v-icon>
                    <b v-if="isAuthoredAndPremium">
                        &nbsp;(Authored Amp)&nbsp;
                    </b>
                    {{
                        isAuthoredAndPremium
                            ? 'Premium Distribution was requested'
                            : 'Enable Premium Distribution for this Amp ($899)'
                    }}
                </div>
            </template>
        </v-checkbox>
        <ul
            class="pl-12 pt-4 body-2"
            :class="{ spaced: $vuetify.breakpoint.mdAndUp }"
        >
            <li>
                Premium Distribution gets your Amp published on world-renowned
                news brands like Business Insider, The Globe & Mail, Bar Chart,
                The Associated Press, Benzinga, Street Insider, and others.
            </li>
            <li>
                A 2nd blog piece is authored for your Amp and published on
                Medium.
            </li>
            <li>
                Your Amp will place higher in news readers like Google News and
                rank higher in Google Search, providing more exposure and
                traffic.
            </li>
            <li v-if="shouldAdvertise && !processingPayment">
                <strong>
                    Buy 1 Premium Distribution Credit Here for $899.
                </strong>
                Our competitors charge up to $1,000 for this type of
                distribution.
            </li>
            <li v-if="isAuthoredAndPremium">
                <b>
                    This Authored Amp is already written and marked as premium.
                </b>
            </li>
            <li v-if="!isEditable && !processingPayment">
                <b>This Amp has already been sent to distribution.</b>
            </li>
            <li v-if="isLimitedDistribution">
                <b>
                    Selective Distribution Amps cannot have Premium
                    distribution.
                </b>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { Watch } from '@/utils/decorators';

import type { Announcement } from '@/types/Announcement';

const AmpPremiumProps = Vue.extend({
    name: 'AmpPremium',
    props: {
        announcement: {
            type: Object as PropType<Announcement>,
            default() {
                return {
                    author_request_assignment: null,
                    press_release: null,
                    blog_post: null,
                    podcast: null,
                    infographic: null,
                    slideshow: null,
                    video_pr: null,
                    movie: null,
                    min_distribution_time: 0,
                    company: null
                };
            }
        },
        processingPayment: {
            type: Boolean,
            default() {
                return false;
            }
        },
        hasPremiumCredits: {
            type: Boolean,
            default() {
                return false;
            }
        },
        isPremium: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component
export default class AmpPremium extends AmpPremiumProps {
    settings = {
        is_premium: this.isPremium
    };

    @Watch('settings', { deep: true })
    onSettingsChange() {
        this.$emit('change', this.settings);
    }

    @Watch('isPremium')
    onIsPremiumChange(isPremium: boolean) {
        this.settings = Object.assign({}, this.settings, {
            is_premium: isPremium
        });
    }

    get canEnablePremiumDistribution() {
        return !(
            this.isAuthoredAndPremium ||
            this.processingPayment ||
            this.isLimited
        );
    }

    get isAuthoredAndPremium() {
        return (
            this.announcement?.author_request_assignment &&
            this.announcement.is_premium
        );
    }

    get isLimited() {
        return !this.isEditable || this.isLimitedDistribution;
    }

    get isEditable() {
        return this.pressRelease?.is_editable;
    }

    get isLimitedDistribution() {
        return Boolean(this.pressRelease?.limited_distribution_only);
    }

    get pressRelease() {
        return this.announcement?.press_release || null;
    }

    get shouldAdvertise() {
        return (
            !this.hasPremiumCredits &&
            (!this.isAuthoredAndPremium || !this.isEditable)
        );
    }

    get isMarkedPremium() {
        return this.pressRelease?.is_premium || this.settings.is_premium;
    }
}
</script>

<style lang="scss" scoped>
.spaced {
    line-height: 1.8;
}
</style>
