<template>
    <div>
        <v-checkbox
            v-model="settings.is_crypto_premium"
            :disabled="!canEnable"
            hide-details
        >
            <template #label>
                <div class="d-flex align-center font-weight-bold">
                    <v-icon small :color="canEnable ? 'green' : 'grey'" left>
                        coins
                    </v-icon>
                    Enable Premium Crypto Distribution for this Amp
                </div>
            </template>
        </v-checkbox>
        <ul
            class="pl-12 pt-4 body-2"
            :class="{ spaced: $vuetify.breakpoint.mdAndUp }"
        >
            <li>
                With Premium Distribution, your Amp will additionally be
                published on dozens of micro-targeted sites, all with placements
                in Google News.
            </li>
            <li>
                Your Amp will place higher in news readers like Google News and
                rank higher in Google Search etc. as a result, for more exposure
                and traffic.
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { Watch } from '@/utils/decorators';

import type { Announcement } from '@/types/Announcement';

const AmpCryptoPremiumProps = Vue.extend({
    name: 'AmpCryptoPremium',
    props: {
        announcement: {
            type: Object as PropType<Announcement>,
            default() {
                return {
                    author_request_assignment: null,
                    press_release: null,
                    blog_post: null,
                    podcast: null,
                    infographic: null,
                    slideshow: null,
                    video_pr: null,
                    movie: null,
                    min_distribution_time: 0
                };
            }
        },
        processingPayment: {
            type: Boolean,
            default() {
                return false;
            }
        },
        isChecked: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component
export default class AmpCryptoPremium extends AmpCryptoPremiumProps {
    settings = {
        is_crypto_premium: false
    };

    @Watch('settings', { deep: true })
    onSettingsChange() {
        this.$emit('change', this.settings);
    }

    @Watch('isChecked')
    onIsCheckedChange(isChecked: boolean) {
        this.settings = { ...this.settings, is_crypto_premium: isChecked };
    }

    get canEnable() {
        return !this.processingPayment && this.isEditable;
    }

    get isEditable() {
        return this.pressRelease?.is_editable;
    }

    get pressRelease() {
        return this.announcement?.press_release || null;
    }
}
</script>

<style lang="scss" scoped>
.spaced {
    line-height: 1.8;
}
</style>
