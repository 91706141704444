import { render, staticRenderFns } from "./AmpModuleHeader.vue?vue&type=template&id=7508fcca&scoped=true&"
import script from "./AmpModuleHeader.vue?vue&type=script&lang=ts&"
export * from "./AmpModuleHeader.vue?vue&type=script&lang=ts&"
import style0 from "./AmpModuleHeader.vue?vue&type=style&index=0&id=7508fcca&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7508fcca",
  null
  
)

export default component.exports