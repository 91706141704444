<template>
    <v-container class="pa-0">
        <v-divider class="mb-6" />
        <v-row>
            <v-col>
                <span class="font-weight-bold">Video Settings</span>
                <v-checkbox
                    v-model="settings.publish_video_public"
                    label="Make video available for anyone to view"
                    class="mb-3"
                    hide-details
                />
                <v-checkbox
                    v-model="settings.publish_video_youtube"
                    label="Distribute on Youtube"
                    hide-details
                />
                <v-checkbox
                    v-model="settings.publish_video_vimeo"
                    label="Distribute to Vimeo"
                    hide-details
                />
                <v-checkbox
                    v-model="settings.publish_video_ubc"
                    label="Distribute to UBC News World"
                    hide-details
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import { Watch } from '@/utils/decorators';

import type { UserOauth } from '@/types/UserOauth';

type Services = {
    youtube: {
        user: null | UserOauth;
        loading: boolean;
    };
    vimeo: {
        user: null | UserOauth;
        loading: boolean;
    };
};

const AmpModuleVideoProps = Vue.extend({
    name: 'AmpModuleVideo',
    props: {
        oauths: {
            type: Array as PropType<UserOauth[]>,
            default() {
                return [];
            }
        },
        publish: {
            type: Boolean,
            default() {
                return true;
            }
        }
    }
});

@Component
export default class AmpModuleVideo extends AmpModuleVideoProps {
    settings = {
        publish_video_public: true,
        publish_video_youtube: true,
        publish_video_vimeo: true,
        publish_video_ubc: true,
        use_user_youtube_account: false,
        use_user_vimeo_account: false
    };

    services: Services = {
        youtube: {
            user: null,
            loading: false
        },
        vimeo: {
            user: null,
            loading: false
        }
    };

    @Watch('oauths')
    onOauthsChange() {
        this.setUserOauths(this.oauths);
    }

    @Watch('settings', { deep: true })
    onSettingsChange() {
        this.$emit('change', this.settings);
    }

    @Watch('premium')
    onPremiumChange() {
        this.updateSettings();
    }

    mounted() {
        this.updateSettings();
    }

    updateSettings() {
        this.settings.publish_video_public = this.publish;
        this.settings.publish_video_youtube = this.publish;
        this.settings.publish_video_vimeo = this.publish;
        this.settings.publish_video_ubc = this.publish;

        if (!this.publish) {
            this.settings.use_user_youtube_account = false;
            this.settings.use_user_vimeo_account = false;
        }
    }

    connectApp(app: keyof Services) {
        this.$store.dispatch('modal/open', `connect-${app}`);
    }

    refreshApp(app: keyof Services) {
        this.services[app].loading = true;

        this.$http
            .get('/connected_apps/')
            .then(({ data }) => {
                this.setUserOauths(data.data.userOauths);
            })
            .finally(() => {
                this.services[app].loading = false;
            });
    }

    setUserOauths(userOauths: UserOauth[]) {
        this.services.youtube.user =
            userOauths.find(oauth => oauth.typeName === 'YouTube') || null;

        this.services.vimeo.user =
            userOauths.find(oauth => oauth.typeName === 'Vimeo') || null;

        if (this.services.youtube.user) {
            this.settings.use_user_youtube_account = true;
        }

        if (this.services.vimeo.user) {
            this.settings.use_user_vimeo_account = true;
        }
    }
}
</script>
