<template>
    <v-dialog v-if="isRendered" v-model="isOpen" max-width="840" persistent>
        <v-card class="modal-wrapper rounded-lg px-6 px-md-16">
            <v-card-title class="pt-8">
                <v-col class="text-center">
                    <h3
                        class="secondary--text"
                        :class="{ 'mr-n12': $vuetify.breakpoint.mdAndUp }"
                    >
                        Publish Full Amp?
                    </h3>
                </v-col>
                <v-col cols="auto">
                    <v-btn
                        icon
                        :class="{ 'mr-n12': $vuetify.breakpoint.mobile }"
                        @click="close"
                    >
                        <v-icon>times</v-icon>
                    </v-btn>
                </v-col>
            </v-card-title>
            <v-card-text class="text-center">
                <p class="pt-4 section-text">
                    You have both AmpiFire credits and PressCable distribution
                    credits.
                </p>
                <p class="pt-4 section-text">
                    Do you want to use an Amp credit to publish all six Amp
                    modules (News Article, Blog Post, Podcast, Infographic,
                    Slideshow, Video) or a PressCable credit to publish just the
                    News Article?
                </p>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-row no-gutters>
                    <v-col class="px-8 py-3">
                        <v-btn
                            :large="!$vuetify.breakpoint.mobile"
                            block
                            color="primary"
                            class="text-h6"
                            @click="confirm(Action.UseAmpiFire)"
                        >
                            Publish Full Amp
                        </v-btn>
                    </v-col>
                    <v-col class="px-8 pt-3 pb-12">
                        <v-btn
                            :large="!$vuetify.breakpoint.mobile"
                            block
                            color="primary"
                            class="text-h6"
                            @click="confirm(Action.UsePressCable)"
                        >
                            Publish Only News Article
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { UseCreditsAction } from '../';

@Component
export default class UseCreditDialog extends Vue {
    id = 'use-credit-dialog';

    Action = UseCreditsAction;

    get isOpen() {
        const isOpen = this.$store?.getters['modal/open'](this.id);

        if (isOpen) {
            this.$emit('open');
        }

        return isOpen;
    }

    get isRendered() {
        return this.$store?.getters['modal/rendered'](this.id);
    }

    confirm(action: UseCreditsAction) {
        this.$emit('action', action);

        this.close(false);
    }

    close(withEmit = true) {
        if (withEmit) {
            this.$emit('close');
        }

        this.$store.dispatch('modal/close', this.id);
    }
}
</script>

<style lang="scss" scoped>
.modal-wrapper {
    &.v-card > .v-card__text {
        color: $secondary-color;

        .section-text {
            font-size: 1.3em;
        }
    }
}
</style>
