import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c(VCheckbox,{attrs:{"disabled":!_vm.canEnableFinancialDistribution,"hide-details":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('div',{staticClass:"d-flex align-center font-weight-bold"},[_c(VIcon,{attrs:{"small":"","color":_vm.canEnableFinancialDistribution
                            ? 'purple lighten-1'
                            : 'grey',"left":""}},[_vm._v(" fa-comment-dollar ")]),(_vm.isAuthoredAndFinancial)?_c('b',[_vm._v("  (Authored Amp)  ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.isAuthoredAndFinancial ? 'Financial Distribution was requested' : 'Enable Financial Distribution for this Amp ($1495)')+" ")],1)]},proxy:true}]),model:{value:(_vm.settings.is_financial),callback:function ($$v) {_vm.$set(_vm.settings, "is_financial", $$v)},expression:"settings.is_financial"}}),_c('ul',{staticClass:"pl-12 pt-4 body-2",class:{ spaced: _vm.$vuetify.breakpoint.mdAndUp }},[_c('li',[_vm._v(" We guarantee publishing on Yahoo! Finance, and possibly NASDAQ. ")]),_c('li',[_vm._v(" Your Amp will place higher in news readers like Google News and rank higher in Google Search, providing more exposure and traffic. ")]),(_vm.shouldAdvertise && !_vm.processingPayment)?_c('li',[_c('strong',[_vm._v(" Buy 1 Financial Distribution Credit Here for $1495. ")])]):_vm._e(),(_vm.isAuthoredAndFinancial)?_c('li',[_c('b',[_vm._v(" This Authored Amp is already written and marked to be distributed to Financial sites. ")])]):_vm._e(),(!_vm.isEditable && !_vm.processingPayment)?_c('li',[_c('b',[_vm._v("This Amp has already been sent to distribution.")])]):_vm._e(),(_vm.isLimitedDistribution)?_c('li',[_c('b',[_vm._v(" Selective Distribution Amps cannot have Financial distribution. ")])]):_vm._e()]),_c(VExpandTransition,[(_vm.settings.is_financial && !_vm.processingPayment)?_c('div',[_c(VDivider,{staticClass:"my-6"}),_c('amp-loa',{attrs:{"announcement":_vm.announcement,"has-many-companies":_vm.hasManyCompanies,"is-first-financial-amp":_vm.isFirstFinancialAmpAndNoPending},on:{"validate":_vm.setLoaValid}})],1):_vm._e()]),(_vm.canViewFinancialInfoOnPublish)?_c('financial-info-modal'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }