import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VContainer,{staticClass:"pa-0"},[_c(VDivider,{staticClass:"mb-6"}),_c(VRow,[_c(VCol,[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Video Settings")]),_c(VCheckbox,{staticClass:"mb-3",attrs:{"label":"Make video available for anyone to view","hide-details":""},model:{value:(_vm.settings.publish_video_public),callback:function ($$v) {_vm.$set(_vm.settings, "publish_video_public", $$v)},expression:"settings.publish_video_public"}}),_c(VCheckbox,{attrs:{"label":"Distribute on Youtube","hide-details":""},model:{value:(_vm.settings.publish_video_youtube),callback:function ($$v) {_vm.$set(_vm.settings, "publish_video_youtube", $$v)},expression:"settings.publish_video_youtube"}}),_c(VCheckbox,{attrs:{"label":"Distribute to Vimeo","hide-details":""},model:{value:(_vm.settings.publish_video_vimeo),callback:function ($$v) {_vm.$set(_vm.settings, "publish_video_vimeo", $$v)},expression:"settings.publish_video_vimeo"}}),_c(VCheckbox,{attrs:{"label":"Distribute to UBC News World","hide-details":""},model:{value:(_vm.settings.publish_video_ubc),callback:function ($$v) {_vm.$set(_vm.settings, "publish_video_ubc", $$v)},expression:"settings.publish_video_ubc"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }