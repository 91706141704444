<template>
    <v-dialog v-if="isRendered" v-model="isOpen" max-width="840" persistent>
        <v-card class="modal-wrapper rounded-lg px-6 px-md-16">
            <v-card-title class="pt-8">
                <v-col class="text-center">
                    <h3
                        class="secondary--text"
                        :class="{ 'mr-n12': $vuetify.breakpoint.mdAndUp }"
                    >
                        Upgrade to Amps
                    </h3>
                </v-col>
                <v-col cols="auto">
                    <v-btn
                        icon
                        :class="{ 'mr-n12': $vuetify.breakpoint.mobile }"
                        @click="close"
                    >
                        <v-icon>times</v-icon>
                    </v-btn>
                </v-col>
            </v-card-title>
            <v-card-text class="text-center">
                <p class="pt-4 section-text">
                    Congratulations, you're ready to publish! You still have
                    PressCable credit(s) you can use to publish this Amp, as
                    soon as they're upgraded.
                </p>
                <p class="pt-4 section-text">
                    You can upgrade a single PressCable credit now OR for an
                    even bigger saving, upgrade ALL your old PressCable credits
                    to Amp Credits at an even bigger discount.
                </p>
            </v-card-text>
            <v-card-actions class="justify-center">
                <v-row no-gutters>
                    <v-col class="px-8 py-3">
                        <v-btn
                            :large="!$vuetify.breakpoint.mobile"
                            block
                            color="primary"
                            class="text-h6"
                            @click="confirm(Action.UpgradeAll)"
                        >
                            Upgrade All My Credits
                        </v-btn>
                    </v-col>
                    <v-col class="px-8 py-3">
                        <v-btn
                            :large="!$vuetify.breakpoint.mobile"
                            block
                            color="primary"
                            class="text-h6"
                            @click="confirm(Action.UpgradeOne)"
                        >
                            Upgrade 1 Credit
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-actions>
            <v-card-actions class="justify-center pt-2 pb-8">
                <v-btn
                    :large="!$vuetify.breakpoint.mobile"
                    text
                    class="publish-news-article font-weight-bold"
                    @click="confirm(Action.DontUpgrade)"
                >
                    {{ denyButtonText }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { UpgradeCreditsAction } from '../';

const UpgradeCreditDialogProps = Vue.extend({
    name: 'UpgradeCreditDialog',
    props: {
        hasBothCredits: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component
export default class UpgradeCreditDialog extends UpgradeCreditDialogProps {
    id = 'upgrade-credit-dialog';

    Action = UpgradeCreditsAction;

    get isOpen() {
        const isOpen = this.$store?.getters['modal/open'](this.id);

        if (isOpen) {
            this.$emit('open');
        }

        return isOpen;
    }

    get isRendered() {
        return this.$store?.getters['modal/rendered'](this.id);
    }

    get denyButtonText() {
        const base = this.hasBothCredits
            ? 'Continue Without Upgrading'
            : 'Just Publish the News Article';

        if (this.$vuetify.breakpoint.mobile) {
            return base;
        }

        return `No Thanks - ${base}`;
    }

    confirm(action: UpgradeCreditsAction) {
        this.$emit('action', action);

        this.close(false);
    }

    close(withEmit = true) {
        if (withEmit) {
            this.$emit('close');
        }

        this.$store.dispatch('modal/close', this.id);
    }
}
</script>

<style lang="scss" scoped>
.modal-wrapper {
    &.v-card > .v-card__text {
        color: $secondary-color;

        .section-text {
            font-size: 1.3em;
        }
    }
    &.v-card > .v-card__actions {
        .publish-news-article {
            font-size: 1em;
        }
    }
}
</style>
