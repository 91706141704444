<template>
    <modal
        :id="id"
        title="Send authorization email before proceeding"
        @close="deny"
    >
        <template #default>
            <p>
                Our records show this is your first Financial Amp for this
                company.
            </p>
            <p>
                Please ensure you have sent the email to
                <a class="d-inline-block" :href="loaMailToLink">
                    {{ businessEmail }}
                </a>
                and CC
                <a class="d-inline-block" :href="loaMailToLink">
                    {{ ccEmail }}
                </a>
                , per the instructions on
                <a
                    href="https://docs.google.com/document/d/1MSVs1vRVFNQInqYDdB_6D0Bqz1V53lFnfnG1mcu5F5s/edit"
                    class="d-inline-block"
                    target="_blank"
                >
                    this page
                </a>
                .
            </p>
            <p>
                You must send this email only once, but you must send it before
                publishing.
            </p>
        </template>
        <template #actions>
            <v-btn text :block="$vuetify.breakpoint.smAndDown" @click="deny">
                I have not sent the email
            </v-btn>
            <v-spacer />
            <v-btn
                color="primary"
                :block="$vuetify.breakpoint.smAndDown"
                @click="confirm"
            >
                I have sent this email
            </v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';

import Component from 'vue-class-component';

import { Modal } from '@/components/Modal';
import { Announcement } from '@/types/Announcement';

const AuthorizationMailSentProps = Vue.extend({
    name: 'AuthorizationMailSent',
    props: {
        announcement: {
            type: Object as PropType<Announcement>,
            default() {
                return {
                    company: null
                };
            }
        }
    }
});

@Component({
    components: {
        Modal
    }
})
export default class AuthorizationMailSent extends AuthorizationMailSentProps {
    id = 'authorization-mail-sent';

    businessName = 'AmpiFire';
    businessEmail = 'support@ampifire.com';
    ccEmail = 'authorization@ampifire.com';

    get loaMailToLink() {
        return [
            `mailto:${this.businessEmail}`,
            [
                `cc=${this.ccEmail}`,
                `subject=${this.loaMailSubject}`,
                `body=${this.loaMailBody}`
            ].join('&')
        ].join('?');
    }

    get loaMailSubject() {
        return `${this.companyName} - Authorize ${this.businessName}`;
    }

    get loaMailBody() {
        return `I authorize ${this.businessName} to publish this and future news articles on behalf of ${this.companyName}.`;
    }

    get companyName() {
        return this.announcement?.company?.name;
    }

    confirm() {
        this.$emit('confirm', true);

        this.close();
    }

    deny() {
        this.$emit('confirm', false);

        this.close();
    }

    close() {
        this.$store.dispatch('modal/close', this.id);
    }
}
</script>
