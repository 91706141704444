<template>
    <v-container fluid class="amp-module-editor px-0">
        <v-slide-y-transition>
            <company-editor
                v-if="company.editing"
                class="px-0"
                :company-id="company.id"
                :user-id="company.user_id"
                :is-creating="company.creating"
                @saved="onSaveCompany"
                @created="onCreateCompany"
                @cancelled="cancelEditingCompany"
            >
                <template #loading>
                    <v-card-title>
                        <v-skeleton-loader loading type="heading" width="40%" />
                    </v-card-title>
                    <v-card-title>
                        <v-skeleton-loader loading type="heading" width="70%" />
                    </v-card-title>
                    <v-card-title>
                        <v-skeleton-loader loading type="heading" width="70%" />
                    </v-card-title>
                    <v-card-text>
                        <v-skeleton-loader loading type="paragraph" />
                    </v-card-text>
                    <v-card-title>
                        <v-skeleton-loader loading type="heading" width="70%" />
                    </v-card-title>
                </template>
            </company-editor>
        </v-slide-y-transition>
        <!-- we cannot use v-if, and have to use v-show here, because form rerenders loosing it's state -->
        <a-form
            v-show="!company.editing"
            ref="form"
            v-slot="{ dirty }"
            :submit="save"
            :disabled="!amp.editable"
        >
            <v-container class="font-weight-medium">
                <v-row>
                    <v-col cols="12" md="8">
                        <v-row>
                            <v-col cols="12" md="6">
                                <a-select-input
                                    v-model="amp.category_id"
                                    :items="categories"
                                    :disabled="isBusy || !amp.editable"
                                    :loading="isLoading"
                                    label="Category"
                                    placeholder="Category"
                                    rules="required"
                                    item-text="text"
                                    item-value="id"
                                    class="mt-3"
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="6">
                                <a-select-input
                                    ref="company"
                                    v-model="amp.company.id"
                                    :items="companies"
                                    :disabled="isBusy || !amp.editable"
                                    :loading="isLoading"
                                    label="Company"
                                    placeholder="Company"
                                    rules="required"
                                    item-text="text"
                                    item-value="id"
                                    class="mt-3"
                                    hide-details
                                />
                            </v-col>
                            <v-col
                                v-if="amp.editable"
                                cols="12"
                                md="6"
                                class="align-end d-flex pb-4"
                                :class="{
                                    'justify-end': $vuetify.breakpoint.smAndDown
                                }"
                            >
                                <v-btn
                                    v-if="amp.company.id"
                                    text
                                    large
                                    color="primary"
                                    class="mr-2"
                                    :disabled="isLoading"
                                    @click="editCompany"
                                >
                                    Edit
                                    <v-icon right small>pencil-alt</v-icon>
                                </v-btn>
                                <v-btn
                                    v-if="canAddCompany"
                                    text
                                    large
                                    color="primary"
                                    :disabled="isLoading"
                                    @click="createCompany"
                                >
                                    Add
                                    <v-icon right small>plus-circle</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-if="isAdmin">
                            <v-col cols="12" md="6">
                                <a-toggle
                                    v-model="amp.limited_distribution_only"
                                    label="Selective Distribution"
                                    :disabled="isBusy"
                                    :loading="isLoading"
                                />
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col>
                        <company-card-small :company="amp.company" flat />
                    </v-col>
                </v-row>
            </v-container>
            <v-container fluid class="pa-0">
                <v-btn
                    type="submit"
                    :loading="isSaving"
                    text
                    block
                    large
                    class="mb-n3 save-button"
                >
                    <v-icon left small>angle-double-up</v-icon>
                    {{ setDirty(dirty) ? 'Save And Close' : 'Close' }}
                </v-btn>
            </v-container>
        </a-form>
    </v-container>
</template>

<script lang="ts">
import Vue from 'vue';
import Component, { mixins } from 'vue-class-component';
import { mapGetters } from 'vuex';

import { AForm } from '@/components/AForm';
import { ASelectInput } from '@/components/AForm/Inputs/ASelectInput';
import { AToggle } from '@/components/AForm/Inputs/AToggle';
import { CompanyEditor } from '@/components/CompanyEditor';
import { CompanyCardSmall } from '@/components/CompanyCardSmall';

import { Endpoint } from '@/mixins';
import { Watch } from '@/utils/decorators';

import type { AmpEdit, Announcement } from '@/types/Announcement';

type IdTextPair = {
    id: number;
    text: string;
};

type AmpInEdit = {
    editable: boolean;
    category_id: number;
    limited_distribution_only: boolean;
    company: {
        id: number;
        user_id: number;
    };
};

const AmpModuleEditorProps = Vue.extend({
    name: 'AmpModuleEditor',
    props: {
        ampId: {
            type: [String, Number],
            default() {
                return 0;
            }
        },
        cache: {
            type: String,
            default() {
                return '';
            }
        },
        canAddCompany: {
            type: Boolean,
            default() {
                return false;
            }
        }
    }
});

@Component({
    components: {
        CompanyCardSmall,
        CompanyEditor,
        AForm,
        ASelectInput,
        AToggle
    },
    computed: {
        ...mapGetters('user', ['isAdmin'])
    }
})
export default class AmpModuleEditor extends mixins(
    AmpModuleEditorProps,
    Endpoint
) {
    isAdmin!: boolean;

    $refs!: {
        form: InstanceType<typeof AForm> & { errors: Record<string, string[]> };
        company: InstanceType<typeof ASelectInput>;
    };

    isEditingCompany = false;

    company = {
        id: 0,
        user_id: 0,
        editing: false,
        creating: true
    };

    isSaving = false;

    cacheId = this.cache;

    amp: AmpInEdit = {
        editable: true,
        category_id: 0,
        company: {
            id: 0,
            user_id: 0
        },
        limited_distribution_only: false
    };

    announcement: Announcement | null = null;

    categories: IdTextPair[] = [];
    companies: IdTextPair[] = [];

    endpoint = '/announcements/edit';

    @Watch('amp.company.id')
    onCompanyChange(id: number, was: number) {
        if (id && was) {
            this.updateCompany();
        }
    }

    get sourceUrl() {
        return [this.endpoint, this.ampId].join('/');
    }

    get isBusy() {
        return this.isLoading || this.isSaving;
    }

    setSaving(isSaving = true) {
        this.$emit('saving', isSaving);
        this.isSaving = isSaving;
    }

    setDirty(isDirty = false) {
        this.$emit('dirty', isDirty);

        return isDirty;
    }

    onMounted() {
        if (this.ampId) {
            this.load();
        }
    }

    onData(data: {
        amp: AmpEdit;
        announcement: Announcement;
        options: {
            categories: Record<number, string>;
            companies: Record<number, string>;
        };
    }) {
        this.announcement = { ...data.announcement };

        this.amp.company = this.announcement?.company || null;
        // very strange/rare scenario
        // TODO: Find the reason
        if (this.amp.company === null) {
            this.amp.company = {
                id: 0,
                user_id: 0
            };
        }

        this.amp.editable = data.amp.editable;
        this.amp.category_id = this.announcement?.category_id || 0;
        this.amp.limited_distribution_only =
            this.announcement.press_release.limited_distribution_only;

        this.categories = this.convertHash(data.options.categories);

        this.companies = this.convertHash(data.options.companies);
    }

    convertHash(data: Record<number, string>) {
        return Object.entries(data)
            .map(([id, text]) => ({
                id: Number(id),
                text
            }))
            .sort((a, b) => a.text.localeCompare(b.text));
    }

    save() {
        if (this.$refs.form?.isDirty) {
            return this.doSave();
        } else {
            this.cancel();
        }
    }

    doSave(closeEditor = true) {
        this.setSaving();

        return this.setData()
            .then(() => {
                return this.onSave(closeEditor);
            })
            .finally(this.setSaving.bind(this, false));
    }

    setData() {
        return this.$http.post(this.sourceUrl, {
            company: {
                id: this.amp.company.id
            },
            company_id: this.amp.company.id,
            limited_distribution_only: this.amp.limited_distribution_only,
            category_id: this.amp.category_id
        });
    }

    async onSave(closeEditor: boolean) {
        return this.reload(closeEditor);
    }

    async reload(closeEditor: boolean) {
        await this.load();
        // refresh parent
        this.$emit('update', true);

        if (closeEditor) {
            this.$emit('dirty', false);
            this.$emit('cancel');
        }
    }

    cancel() {
        this.$emit('dirty', false);

        this.$emit('cancel');
    }

    setCacheId() {
        if (!this.cacheId) {
            this.cacheId = this.getCacheId();
        }
    }

    editCompany() {
        this.company.id = this.amp.company.id;
        this.company.creating = false;

        this.showCompanyEditor();
    }

    createCompany() {
        this.company.id = 0;
        // create company for the current amp owner
        this.company.user_id = this.amp.company.user_id;
        this.company.creating = true;

        this.showCompanyEditor();
    }

    showCompanyEditor(isEditing = true) {
        this.company.editing = isEditing;
    }

    async cancelEditingCompany() {
        this.showCompanyEditor(false);
    }

    onSaveCompany() {
        if (this.company.creating) {
            return true;
        }

        this.cancelEditingCompany();

        this.reload(false);
    }

    async onCreateCompany(id: number) {
        this.cancelEditingCompany();

        await this.reload(false);

        this.amp.company.id = id;
        // manual change of model value won't trigger the validation
        // we have to do that explicitly
        this.$refs.company.$refs.provider.setFlags({
            changed: true,
            dirty: true,
            touched: true
        });
    }

    async updateCompany() {
        if (this.amp.company.id) {
            return this.$http
                .get(`/companies/view/${this.amp.company.id}`)
                .then(({ data }) => {
                    if (data.data.company) {
                        this.amp.company = data.data.company;
                    }
                });
        }
    }
}
</script>

<style lang="scss" scoped>
.amp-module-editor::v-deep {
    background-color: $white;
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.1);
    position: relative;
    z-index: 3;

    .save-button {
        background-color: $blueish;
        border-radius: 0px;

        .v-btn__content {
            text-transform: uppercase !important;
        }
    }
}
</style>
