<template>
    <modal id="msn-info-modal" title="MSN Distribution" :closable="false">
        <a-alert type="info">
            This will require extensive edits due to
            <router-link
                to="/guidelines/msn"
                target="_blank"
                class="primary--text d-inline-flex"
            >
                MSN's content policies.
            </router-link>
            The Amp will be returned to the authoring team and will require
            publishing again after changes are made.
        </a-alert>

        <template #actions>
            <v-spacer />
            <v-btn color="primary" @click.stop="close">Continue</v-btn>
        </template>
    </modal>
</template>

<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';

import { Modal } from '@/components/Modal';
import { AAlert } from '@/components/AAlert';

@Component({
    components: {
        Modal,
        AAlert
    }
})
export default class MsnInfoModal extends Vue {
    close() {
        this.$store.dispatch('modal/close', 'msn-info-modal');
    }
}
</script>
