<template>
    <div>
        <v-checkbox
            v-model="acceptedTerms"
            hide-details
            :error="hasError && !acceptedTerms"
            @change="onAccept"
        >
            <template #label>
                <div class="font-weight-bold">
                    By choosing to publish, you agree to our
                    <router-link
                        class="d-inline-block"
                        to="/profile/terms-of-service"
                        target="_blank"
                        @click.native="onTermsLinkClick"
                    >
                        Terms of Service
                    </router-link>
                </div>
            </template>
        </v-checkbox>
        <v-checkbox
            v-model="acceptedGuidelines"
            hide-details
            :error="hasError && !acceptedGuidelines"
            @change="onAccept"
        >
            <template #label>
                <div class="font-weight-bold">
                    I have reviewed my Amp. Once my content is submitted, it is
                    final and cannot be edited.
                </div>
            </template>
        </v-checkbox>
        <ul
            class="pl-12 pt-4 body-2"
            :class="{ spaced: $vuetify.breakpoint.mdAndUp }"
        >
            <li>
                I have read the
                <router-link
                    class="d-inline-block"
                    to="/profile/terms-of-service#amp-guidelines"
                    target="_blank"
                >
                    Amp Guidelines
                </router-link>
                and understand that this release will be rejected if it fails to
                meet the guidelines.
            </li>
            <li>
                I fully understand that submitting low-quality content for
                publication could mean my account and domain name are
                blacklisted from submitting any future Amps to AmpiFire's
                distribution partners.
            </li>
        </ul>
        <v-alert
            v-show="isWebsiteMissing"
            class="mt-4"
            type="error"
            outlined
            border="left"
            icon="exclamation-circle"
        >
            <v-row align="center">
                <v-col class="grow">
                    Please provide a
                    <strong>website</strong>
                    for your company. It should be a valid url including
                    protocol (http / https). You can update it from your
                    selected company's
                    <a
                        :href="companyEditUrl"
                        target="_blank"
                        class="d-inline-block"
                    >
                        edit
                    </a>
                    page.
                </v-col>
                <v-col class="shrink">
                    <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                            <v-btn
                                icon
                                small
                                v-bind="attrs"
                                :loading="isCheckingWebsite"
                                v-on="on"
                                @click="checkWebsite"
                            >
                                <v-icon small>sync</v-icon>
                            </v-btn>
                        </template>
                        <span>Refresh</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </v-alert>
    </div>
</template>

<script lang="ts">
import Vue, { PropType } from 'vue';
import Component from 'vue-class-component';

import type { Announcement } from '@/types/Announcement';
import { uuid } from '@/utils/helpers';

const AmpTermsProps = Vue.extend({
    name: 'AmpTerms',
    props: {
        hasError: {
            type: Boolean,
            default() {
                return false;
            }
        },
        announcement: {
            type: Object as PropType<Announcement>,
            default() {
                return {
                    press_release: null,
                    company_id: null,
                    company: {
                        website: ''
                    }
                };
            }
        }
    }
});
@Component
export default class AmpTerms extends AmpTermsProps {
    acceptedTerms = false;
    acceptedGuidelines = false;

    isCheckingWebsite = false;

    get pressRelease() {
        return this.announcement?.press_release || null;
    }

    get isWebsiteMissing() {
        return !this.announcement?.company?.website;
    }

    get companyEditUrl() {
        // keep /app, as we open it in a new tab
        return `/app/profile/companies/${this.announcement.company_id}`;
    }

    onAccept() {
        this.$emit('accepted', this.acceptedTerms && this.acceptedGuidelines);
    }

    checkWebsite() {
        if (this.pressRelease) {
            this.isCheckingWebsite = true;

            this.$http
                .get(`/companies/view/${this.announcement?.company_id}`, {
                    id: this.getCacheId() // don't cache
                })
                .then(({ data }) => {
                    if (this.announcement && data.data.company?.website) {
                        this.announcement.company.website =
                            data.data.company.website;
                    }
                })
                .finally(() => {
                    this.isCheckingWebsite = false;
                });
        }
    }

    getCacheId() {
        return uuid();
    }

    onTermsLinkClick(e: MouseEvent) {
        e.stopImmediatePropagation();
    }
}
</script>

<style lang="scss" scoped>
.spaced {
    line-height: 1.8;
}
</style>
